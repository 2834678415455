// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/render/project/src/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/render/project/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-application-index-js": () => import("/opt/render/project/src/src/pages/application/index.js" /* webpackChunkName: "component---src-pages-application-index-js" */),
  "component---src-pages-careers-index-js": () => import("/opt/render/project/src/src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-nbt-js": () => import("/opt/render/project/src/src/pages/careers/nbt.js" /* webpackChunkName: "component---src-pages-careers-nbt-js" */),
  "component---src-pages-careers-nbt-design-js": () => import("/opt/render/project/src/src/pages/careers/nbt/design.js" /* webpackChunkName: "component---src-pages-careers-nbt-design-js" */),
  "component---src-pages-careers-nbt-engineering-js": () => import("/opt/render/project/src/src/pages/careers/nbt/engineering.js" /* webpackChunkName: "component---src-pages-careers-nbt-engineering-js" */),
  "component---src-pages-careers-nbt-marketing-js": () => import("/opt/render/project/src/src/pages/careers/nbt/marketing.js" /* webpackChunkName: "component---src-pages-careers-nbt-marketing-js" */),
  "component---src-pages-careers-nbt-operations-js": () => import("/opt/render/project/src/src/pages/careers/nbt/operations.js" /* webpackChunkName: "component---src-pages-careers-nbt-operations-js" */),
  "component---src-pages-careers-nbt-venturedevelopment-js": () => import("/opt/render/project/src/src/pages/careers/nbt/venturedevelopment.js" /* webpackChunkName: "component---src-pages-careers-nbt-venturedevelopment-js" */),
  "component---src-pages-corporates-index-js": () => import("/opt/render/project/src/src/pages/corporates/index.js" /* webpackChunkName: "component---src-pages-corporates-index-js" */),
  "component---src-pages-corporates-industries-js": () => import("/opt/render/project/src/src/pages/corporates/industries.js" /* webpackChunkName: "component---src-pages-corporates-industries-js" */),
  "component---src-pages-corporates-phases-js": () => import("/opt/render/project/src/src/pages/corporates/phases.js" /* webpackChunkName: "component---src-pages-corporates-phases-js" */),
  "component---src-pages-data-protection-index-js": () => import("/opt/render/project/src/src/pages/data-protection/index.js" /* webpackChunkName: "component---src-pages-data-protection-index-js" */),
  "component---src-pages-faq-careers-js": () => import("/opt/render/project/src/src/pages/faq/careers.js" /* webpackChunkName: "component---src-pages-faq-careers-js" */),
  "component---src-pages-faq-founders-js": () => import("/opt/render/project/src/src/pages/faq/founders.js" /* webpackChunkName: "component---src-pages-faq-founders-js" */),
  "component---src-pages-faq-index-js": () => import("/opt/render/project/src/src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-faq-startups-js": () => import("/opt/render/project/src/src/pages/faq/startups.js" /* webpackChunkName: "component---src-pages-faq-startups-js" */),
  "component---src-pages-femalefounders-index-js": () => import("/opt/render/project/src/src/pages/femalefounders/index.js" /* webpackChunkName: "component---src-pages-femalefounders-index-js" */),
  "component---src-pages-founders-index-js": () => import("/opt/render/project/src/src/pages/founders/index.js" /* webpackChunkName: "component---src-pages-founders-index-js" */),
  "component---src-pages-imprint-index-js": () => import("/opt/render/project/src/src/pages/imprint/index.js" /* webpackChunkName: "component---src-pages-imprint-index-js" */),
  "component---src-pages-index-js": () => import("/opt/render/project/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-relations-company-portrait-js": () => import("/opt/render/project/src/src/pages/investor-relations/company-portrait.js" /* webpackChunkName: "component---src-pages-investor-relations-company-portrait-js" */),
  "component---src-pages-investor-relations-corporate-news-js": () => import("/opt/render/project/src/src/pages/investor-relations/corporate-news.js" /* webpackChunkName: "component---src-pages-investor-relations-corporate-news-js" */),
  "component---src-pages-investor-relations-long-term-view-js": () => import("/opt/render/project/src/src/pages/investor-relations/long-term-view.js" /* webpackChunkName: "component---src-pages-investor-relations-long-term-view-js" */),
  "component---src-pages-portfolio-assistme-js": () => import("/opt/render/project/src/src/pages/portfolio/assistme.js" /* webpackChunkName: "component---src-pages-portfolio-assistme-js" */),
  "component---src-pages-portfolio-concr-js": () => import("/opt/render/project/src/src/pages/portfolio/concr.js" /* webpackChunkName: "component---src-pages-portfolio-concr-js" */),
  "component---src-pages-portfolio-deflect-js": () => import("/opt/render/project/src/src/pages/portfolio/deflect.js" /* webpackChunkName: "component---src-pages-portfolio-deflect-js" */),
  "component---src-pages-portfolio-index-js": () => import("/opt/render/project/src/src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-metr-js": () => import("/opt/render/project/src/src/pages/portfolio/metr.js" /* webpackChunkName: "component---src-pages-portfolio-metr-js" */),
  "component---src-pages-portfolio-sensry-js": () => import("/opt/render/project/src/src/pages/portfolio/sensry.js" /* webpackChunkName: "component---src-pages-portfolio-sensry-js" */),
  "component---src-pages-portfolio-weeve-js": () => import("/opt/render/project/src/src/pages/portfolio/weeve.js" /* webpackChunkName: "component---src-pages-portfolio-weeve-js" */),
  "component---src-pages-privacy-index-js": () => import("/opt/render/project/src/src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-startups-index-js": () => import("/opt/render/project/src/src/pages/startups/index.js" /* webpackChunkName: "component---src-pages-startups-index-js" */),
  "component---src-pages-ventures-index-js": () => import("/opt/render/project/src/src/pages/ventures/index.js" /* webpackChunkName: "component---src-pages-ventures-index-js" */)
}

